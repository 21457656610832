import { Controller } from "@hotwired/stimulus"
var indexVal = 0
export default class extends Controller {
  static targets = ["space", "val", "option"]

  static values = {
    index: Number,
    field: String,
    current: String
  }

  connect() {
    console.log("Working")
  }

  updateBlockType(e){
    e.preventDefault()
    e.stopImmediatePropagation()

    
  }

  addAbove(e){
    e.preventDefault()
    e.stopImmediatePropagation();

    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 50) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    indexVal = parseInt(e.target.dataset.value) + 1

    var idVal = result 

    counter = 0;
    while (counter < 50) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    var optionInit = result

    var panel = `
    <div data-form-creation-target="space" 
    data-form-creation-current-value="form-creation-manager#getData" 
    data-controller="form-creation" 
    class="form-section" >
      
      <button 
      type="button"
      data-action="click->form-creation-manager#addData form-creation#addAbove"
      data-value="${idVal}"> 
        Add Input Above 
      </button> 
      <button 
      type="button"
      data-action="click->form-creation-manager#addData form-creation#addBelow"
      data-value="${idVal}"> 
        Add Input Below 
      </button> 

      <button
      type="button"
      data-action="click->form-creation#removeOption"
      data-value="${idVal}">
        Delete Input
      </button> 

      <select style="display:inline-block" id="${idVal}" name="${idVal}" data-action="change->form-creation#changeDropdown">
        <option value="Text Box">Text Box</option>
        <option value="Text Area">Text Area</option>
        <option value="Select">Dropdown</option>
        <option value="Date">Date</option>
        <option value="Time">Time</option>
        <option value="Date and Time">Date and Time</option>
        <option value="Email">Email</option>
        <option value="Checkbox">Checkbox</option>
        <option value="Radio">Radio</option>
        <option calue="Phone">Phone</option>
      </select> 

      <label for="${idVal}_labl">Label</label>
      <input type="text" id="${idVal}_labl" name="${idVal}_labl" placeholder="label">
      <!--<textarea id="${idVal}_text" name="${idVal}_text"></textarea>-->

      <div id="options_section" class="options_section"></div>

    </div>
    `
    console.log(e.target.dataset.value)
    // e.target.insertAdjacentHTML('beforebegin', e.target.dataset.fields.replace(/new_field/g, new Date().getTime()))
    this.element.insertAdjacentHTML("beforebegin", panel)
  }

  addBelow(e){
    e.preventDefault()
    e.stopImmediatePropagation();

    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 50) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    indexVal = parseInt(e.target.dataset.value) + 1

    var idVal = result 

    counter = 0;
    while (counter < 50) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    var optionInit = result

    var panel = `
    <div data-form-creation-target="space" 
    data-form-creation-current-value="form-creation-manager#getData" 
    data-controller="form-creation" 
    class="form-section" >
      
      <button 
      type="button"
      data-action="click->form-creation-manager#addData form-creation#addAbove"
      data-value="${idVal}"> 
        Add Input Above 
      </button> 
      <button 
      type="button"
      data-action="click->form-creation-manager#addData form-creation#addBelow"
      data-value="${idVal}"> 
        Add Input Below 
      </button> 

      <button
      type="button"
      data-action="click->form-creation#removeOption"
      data-value="${idVal}">
        Delete Input
      </button> 

      <select style="display:inline-block" id="${idVal}" name="${idVal}" data-action="change->form-creation#changeDropdown">
        <option value="Text Box">Text Box</option>
        <option value="Text Area">Text Area</option>
        <option value="Select">Dropdown</option>
        <option value="Date">Date</option>
        <option value="Time">Time</option>
        <option value="Date and Time">Date and Time</option>
        <option value="Email">Email</option>
        <option value="Checkbox">Checkbox</option>
        <option value="Radio">Radio</option>
        <option value="Phone">Phone</option>
        <option value="Recap">ReCaptcha</option>
      </select> 

      <label for="${idVal}_labl">Label</label>
      <input type="text" id="${idVal}_labl" name="${idVal}_labl" placeholder="label">
      <!--<textarea id="${idVal}_text" name="${idVal}_text"></textarea>-->

      <div id="options_section" class="options_section"></div>

    </div>
    `

    console.log(this.element)
    // e.target.insertAdjacentHTML('beforebegin', e.target.dataset.fields.replace(/new_field/g, new Date().getTime()))
    this.element.insertAdjacentHTML("afterend", panel)
  }

  addOption(e){
    e.preventDefault()
    e.stopImmediatePropagation();

    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 50) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    var panel = `
    <div class="option-section" data-form-creation-target="option">
      <div style="padding: 0 6px"> &bull; </div>
      <input>
    </div>
    `

    var lastItem = this.optionTargets.length - 1

    this.optionTargets[lastItem].insertAdjacentHTML("afterend", panel)
  }

  changeDropdown(e){
    e.preventDefault()
    e.stopImmediatePropagation();

    console.log(e.target.selectedIndex)
    var a = this.element.getElementsByClassName("options_section")[0]
    if((e.target.selectedIndex == 2 || e.target.selectedIndex == 7 || e.target.selectedIndex == 8) && a.innerHTML == ""){
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < 50) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      let resultB = result + "jv6"
      let resultC = result + "280jcsed"
      a.innerHTML = `
      <div> Options </div>
      <div id="${result}_optn" data-controller="form-control-option" >
        <button 
          type="button"
          data-action="click->form-control-option#addOption">
          Add Option 
        </button> 
        <!--<button 
          type="button"
          data-action="click->form-control-option#removeOption">
          Remove Option
        </button> -->
        <div class="option-section" data-form-creation-target="option">
          <div style="padding: 0 6px"> &bull; </div>
          <input id="${result}_optn" name="${result}_optn" value="Option 1">
        </div>
      </div>
      <div id="${resultB}_optn" data-controller="form-control-option" >
        <button 
          type="button"
          data-action="click->form-control-option#addOption">
          Add Option 
        </button> 
        <button 
          type="button"
          data-action="click->form-control-option#removeOption">
          Remove Option
        </button>
        <div class="option-section" data-form-creation-target="option">
          <div style="padding: 0 6px"> &bull; </div>
          <input id="${resultB}_optn" name="${resultB}_optn" value="Option 2">
        </div>
      </div>
      <div id="${resultC}_optn" data-controller="form-control-option" >
        <button 
          type="button"
          data-action="click->form-control-option#addOption">
          Add Option 
        </button> 
        <button 
          type="button"
          data-action="click->form-control-option#removeOption">
          Remove Option
        </button>
        <div class="option-section" data-form-creation-target="option">
          <div style="padding: 0 6px"> &bull; </div>
          <input id="${resultC}_optn" name="${resultC}_optn" value="Option 3">
        </div>
      </div>
      `
    }else if((e.target.selectedIndex == 2 || e.target.selectedIndex == 7 || e.target.selectedIndex == 8)){
      return
    }else{
      a.innerHTML = ""
    }
    if(e.target.selectedIndex == 10){
      console.log("Oh hey, Captcha")
      a.innerHTML = `<br><h5>ReCaptcha Values</h5>
      <label>Public Key</label>
      <input id="recap_pubkey" name="recap_pubkey">
      <label>Secret Key</label>
      <input id="recap_secret" name="recap_secret">
      `
    }
  }

  removeOption(e){
    e.preventDefault()
    e.stopImmediatePropagation();

    console.log(this.element)

    this.element.outerHTML = null
  }

}
