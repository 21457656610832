import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["fields"]

  hide(e){
    // e.target.closest("[data-target='fields-for.fields']").style = "display: none;"
  }

  connect() {
    console.log("STIMULUS IS WORKING")
  }

  add(e){
    console.log("TEST");
    // e.preventDefault()
    // e.target.insertAdjacentHTML('beforebegin', e.target.dataset.fields.replace(/new_field/g, new Date().getTime()))
  }

}
