import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  pullFormData(e){
    e.preventDefault()
    e.stopImmediatePropagation();

    
  }
  
}