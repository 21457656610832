import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  addOption(e){
    e.preventDefault()
    e.stopImmediatePropagation();

    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 50) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    
    var panel = `
    <div id="${result}_optn" data-controller="form-control-option" >
      <button 
        type="button"
        data-action="click->form-control-option#addOption">
        Add Option 
      </button> 
      <button 
        type="button"
        data-action="click->form-control-option#removeOption">
        Remove Option
      </button> 
      <div class="option-section" data-form-creation-target="option">
        <div style="padding: 0 6px"> &bull; </div>
        <input id="${result}_optn" name="${result}_optn">
      </div>
    </div>
    `

    this.element.insertAdjacentHTML("afterend", panel)
  }

  removeOption(e){
    e.preventDefault()
    e.stopImmediatePropagation();

    this.element.remove()
  }
}